<script setup lang="ts">
  import type { User } from '~/models/User'
  import AuthenticationService from '~/services/AuthenticationService'
  import DashboardMenuService from '~/services/DashboardMenuService'
  import UserRole from '~/models/enums/UserRole'
  import type { NavigationMenuItem } from '~/models/NavigationMenuItem'

  const router = useRouter()
  const loggedUser: Partial<User> = AuthenticationService.getLoggedUser()
  const navigation = ref<NavigationMenuItem[]>([])

  const setActiveMenuItem = (itemSlug: string) => {
    // set all items as not active
    navigation.value.forEach((item) => (item.current = false))
    // find item in navigation by name and set it as active
    const item = navigation.value.find((item) => item.slug === itemSlug)
    if (item !== undefined) item.current = true
    else navigation.value[0].current = true
  }

  onMounted(() => {
    navigation.value = DashboardMenuService.getStaticMenuItens(
      loggedUser.role ?? UserRole.USER
    )
    const route = router.currentRoute.value.path.split('/')
    const routeSlug = route[route.length - 1]
    setActiveMenuItem(routeSlug)
  })
</script>

<template>
  <div class="p-0">
    <ul class="flex flex-row">
      <li
        v-for="item in navigation"
        :key="item.slug"
        :class="[item.current ? 'shadow-bottom' : '']"
        :aria-selected="item.current"
        class="group w-28 text-center hover:shadow-bottom">
        <div class="py-4">
          <NuxtLink
            :to="item.href"
            class="cursor-pointer"
            @click="setActiveMenuItem(item.slug)">
            <component
              :is="item.icon"
              :key="item.name"
              class="mx-auto h-7 w-7 stroke-1"
              aria-hidden="true" />
            <p
              class="mt-2 text-xs font-light group-hover:text-orange-500 group-aria-selected:text-orange-500">
              {{ item.name }}
            </p>
          </NuxtLink>
        </div>
      </li>
    </ul>
  </div>
</template>
